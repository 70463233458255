@import '../../stylesheets/variables';

.rich-text-input {
  .editor-container {
    border-width: 1px;
    border-color: $border-color;
    border-radius: 1px;
  }

  &.has-error {
    .editor-container {
      border-color: $red;
    }
  }
}
