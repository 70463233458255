@import '../../NavBarVariables.scss';

.community-basic-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    height: $avatar-width;
    width: $avatar-width;
    border-radius: 50%;
    margin-right: $grid-gap-basis;

    &.collapsed {
      margin-right: 0;
    }
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: $text-5;
  }
}
