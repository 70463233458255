@import '../../../stylesheets/_variables.scss';

.event-call-button.call-button {
  &:after {
    background: center no-repeat url('../../../images/icons/checked_teal.svg');
  }

  &:hover::after {
    background: center no-repeat url('../../../images/icons/checked.svg');
  }
}
