@import '../../stylesheets/variables';

.empty-state-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $grid-gap-basis * 2;
  text-align: center;
  max-width: 80%;
  margin: auto;

  img {
    height: 132px;
    object-fit: cover;
  }

  p {
    padding-top: $grid-gap-basis;
  }

  .title {
    font-weight: bold;
  }

  .action {
    padding-top: $grid-gap-basis * 2;
  }
}
