@import '../../stylesheets/variables';

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  .header-left,
  .header-right {
    display: flex;
    align-items: center;
  }

  .header-left {
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;

      &.call-header {
        width: 23px;
        height: 23px;
      }

      path {
        fill: $text-3;
      }

      &.stroke-icon path {
        fill: none;
        stroke: $text-3;
      }
    }

    .section-title {
      margin-right: 7px;
      color: $text-3;
    }

    p {
      line-height: 22px;
    }

    .link {
      margin-left: 8px;
      font-size: 14px;

      svg {
        height: 14px;
        width: 14px;
        margin-right: 4px;

        path {
          fill: currentColor;
        }
      }
    }
  }

  .section-total {
    margin-right: 11px;
  }
}
