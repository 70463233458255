@import '../../stylesheets/variables';

.leadership {
  padding-bottom: 20px;

  .empty-root {
    margin-bottom: 240px;
  }
}

.load-button {
  display: flex;
  justify-content: center;
  margin: 45px 0 70px;
}
