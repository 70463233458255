.change-avatar-modal {
  .swal2-popup {
    padding: 24px;
  }

  .swal2-header {
    padding: 24px 24px 0;
  }

  .change-avatar-modal-body {
    .preview {
      margin: 36px auto 24px;
      height: 272px;
      width: 272px;
      display: block;
      border-radius: 50%;
    }

    .delete-button {
      position: absolute;
      bottom: 24px;
      left: 24px;
      z-index: 9999;
      height: 40px;
      width: 67px;
      min-width: 67px;
      padding: 0;

      svg {
        margin: 0;

        path {
          fill: none;
        }
      }
    }
  }
}
