.terms-modal {
  .swal2-popup {
    padding: 48px 64px 32px;

    .checkbox-component-wrapper {
      flex-direction: row;
      margin-bottom: 0;

      .input-wrapper {
        margin-top: 4px;
      }

      .link {
        display: inline;
      }
    }

    .button.primary {
      width: 110px;
    }
  }
}