@import '../../stylesheets/variables';

body.ReactModal__Body--open {
  overflow: hidden;
}

.mindr-modal-overlay {
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mindr-modal {
  margin: auto;
  border-radius: 4px;
  background: #fff;
  border: none;
  position: relative;
  box-sizing: border-box;
  padding: $grid-gap-basis * 3;

  &.small {
    max-width: $grid-gap-basis * 51;
  }

  &.large {
    max-width: $grid-gap-basis * 58;
  }

  &.wide {
    max-width: $grid-gap-basis * 72;
  }
}

.mindr-modal-title,
.mindr-modal-subtitle {
  font-size: $fs-paragraph;
  line-height: calc(22px / $fs-paragraph);
  // margin-bottom: $grid-gap-basis * 2;
}

.mindr-modal-title {
  color: $text-3;

  &.large {
    color: $teal-dark;
  }
}

.mindr-modal-subtitle {
  color: $text-5;
  margin-bottom: $grid-gap-basis * 2;
}

.mindr-modal-body {
  margin-top: $grid-gap-basis;
}

.mindr-modal-close {
  position: absolute;
  top: ($grid-gap-basis * 3);
  right: ($grid-gap-basis * 3);
  height: 15px;

  svg {
    path {
      fill: $text-3;
    }
  }
}

.mindr-modal-close:hover {
  cursor: pointer;
}

.mindr-modal-actions {
  margin-top: $grid-gap-basis * 3;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;

  .button {
    margin-right: $grid-gap-basis;

  &:first-child {
      margin-right: 0;
    }
  }
}

.mindr-modal-section {
  margin-top: $grid-gap-basis * 2;
  border-top: 1px solid $border-color;
  padding-top: $grid-gap-basis * 2;

  &:first-child {
    border: none;
    padding-top: 0;
  }
}

.publishing-modal-content {
  width: 544px;
  height: 177px;
  display: flex;
  justify-content: center;
  align-items: center;
}