@import '../../stylesheets/variables';

.edit-log-card {
  $edit-log-modal-padding: 56px;

  .loading-indicator {
    padding: 0 $edit-log-modal-padding;
  }

  .GojiCustomTable-wrapper {
    box-shadow: none;

    .GojiCustomTable tr {
      th {
        border: none;
        padding: 10px;
      }

      td {
        background-color: $background-page;
        border: none;
        padding: 10px;
      }

      td:first-child .GojiCustomTable__cell-value:first-child {
        padding-left: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: $text-5;
      }

      td:last-child .GojiCustomTable__cell-value:last-child {
        padding-right: 10px;
      }
    }
  }
}
