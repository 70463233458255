@import '../../../stylesheets/_variables.scss';

$textarea-line-height: 20px;
$textarea-vertical-padding: 20px;

.register-confirmation-modal {
  .input-component-wrapper textarea {
    min-height: ($textarea-line-height * 7) + $textarea-vertical-padding;
  }
}
