@import '../../stylesheets/variables';

.communities {
  padding-bottom: 20px;

  .empty-root {
    margin-bottom: 240px;
  }
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 26px;

  .input-component-wrapper {
    margin-bottom: 0;
  }

  .switch-label {
    font-size: 14px;
    line-height: 1.61;
    color: $text-3;
  }
}
