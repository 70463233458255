@import './NavBarVariables.scss';

.navbar-container {
  display: flex;
  flex-direction: column;
  width: $sidebar-width;
  transition: width 0.5s ease-in;
  overflow: hidden;
  height: calc(100% - $header-height);
  position: fixed;
  background-color: $background-white;
  box-shadow: $shadow;
  z-index: $side-bar-z-index;
  
  &.no-anim {
    transition: width 0.0s linear !important;
  }

  &.collapsed {
    width: $collapsed-sidebar-width;
  }

  &.mobile-nav {
    width: 100vw;
    height: calc(100vh - 42px);
    overflow-x: hidden;
    overflow-y: scroll;
    transition: opacity 300ms ease-in-out, height 0ms linear 300ms !important;

    &:not(.mobile-nav-open) {
      height: 0vh;
      opacity: 0.0;
      transition: opacity 300ms ease-in-out, height 0ms linear 300ms !important;
    }

    &.mobile-nav-open {
      height: calc(100vh - 42px);
      opacity: 1.0;
      transition: opacity 300ms ease-in-out, height 0ms linear 0ms !important;
    }
  }

  .header {
    margin-bottom: 36px;

    .link {
      position: relative;
      min-height: 32px;
      display: block;
      font-size: 14px;
      line-height: $collapse-button-width;
      white-space: nowrap;

      span {
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        text-align: left;

        &.when-collapsed {
          right: unset;
          width: 100%;
          max-width: $avatar-width;
          text-align: center;
        }
      }
    }
  }
}

.navbar {
  flex: 1 0;
  width: $sidebar-width;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $grid-gap-basis * 2;
  transition: width 0.5s ease-in;

  .no-anim & {
    transition: width 0.0s linear !important;
  }

  &.collapsed {
    // width: $collapsed-sidebar-width;
    overflow-x: hidden;
  }

  .mobile-nav & {
    width: 100%;
    position: relative;
  }

  section {
    margin-bottom: $grid-gap-basis * 4;

    &:last-child {
      margin-bottom: $grid-gap-basis * 2;
    }
  }

  .bottom {
    .logout {
      padding: $nav-bar-button-padding;
      padding-left: $main-horizontal-padding;
      bottom: 0;
    }

    .logout-icon {
      padding: $nav-bar-button-padding;
      & svg {
        width: 15px;
        height: 15px;
      }
    }

    margin-bottom: $grid-gap-basis * 2 + $footer-height;
  }
}

.main.with-sidebar.collapsed {
  padding-left: $collapsed-sidebar-width;

  &.no-anim {
    transition: padding-left 0.0s linear !important;
  }
}
