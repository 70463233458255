@import '../../stylesheets/variables';
@import '../../stylesheets/mixins';

.event-call-div {
  position: relative;

  &.small {
    position: relative;
    max-width: calc(50% - 24px);

    .date-badge {
      margin-right: 16px;
    }

    .content {
      .event-logo {
        width: 104px;
        min-width: 104px;
        height: 104px;
        margin-right: 11px;
      }

      .content-text {
        margin-bottom: 0;
      }
    }
  }

  &:not(.small) {
    .content {
      min-height: 208px;

      @include responsive(1200px) {
        .event-logo {
          height: 120px;
          width: 120px;
          min-width: 120px;
        }

        .content-text {
          width: calc(100% - 142px);
        }
      }
    }
  }
}

.event {
  background: #fff;
  border-radius: 4px;
  box-shadow: $shadow;
  display: flex;
  position: relative;
  height: 100%;
  padding: 24px;
  text-decoration: none;
  transition: all 0.4s ease;

  .subscribed-indicator {
    display: none;
    position: absolute;
    bottom: 18px;
    right: 25px;
  }

  &.subscribed {
    .subscribed-indicator {
      display: block;
    }

    .info {
      .buttons {
        .register-event-call-btn {
          display: none;
        }
      }
    }
  }

  .date-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $blue;
    margin-top: -5px;
    margin-right: 24px;

    .day {
      font-weight: 700;
      font-size: 11px;
      line-height: 1.45;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: currentColor;
    }

    .date {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 1.06;
      color: currentColor;
    }
  }

  .call-avatar {
    width: 52px;
    height: 52px;
    min-width: 52px;
    border-radius: 26px;
    background-color: $orange-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    & svg {
      width: 50%;
      height: 50%;
      path {
        stroke: #fff;
      }
    }
  }

  .content {
    display: flex;

    .event-logo {
      width: 177px;
      min-width: 177px;
      height: 177px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 22px;
      border-radius: 2px;
    }

    .content-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      color: $text-3;
      margin-bottom: 30px;
      margin-right: 24px;

      .type {
        font-weight: 700;
        font-size: 12px;
        line-height: calc(20 / 12);
        letter-spacing: 1px;
        color: $text-3;
        text-transform: uppercase;
        margin-bottom: 6px;

        .pill {
          margin-left: 6px;
          vertical-align: top;
        }
      }

      .title {
        margin-bottom: 16px;
        max-width: 254px;
      }

      .detail {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .caption {
          line-height: 14px;
        }

        &:last-child {
          margin-bottom: 30px + 6px;
        }

        .cohost-more {
          margin-left: 6px;
        }

        svg,
        .empty-icon {
          width: 14px;
          min-width: 14px;
          height: 14px;
          margin-right: 9px;

          path {
            fill: $blue;
          }

          &.icon-stroke path {
            fill: none;
            stroke: $blue;
          }
        }
      }
    }
  }

  &:focus {
    box-shadow: $shadow-hover;

    .content {
      .title {
        color: $text-3;
      }
    }
  }

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

    .content {
      .title {
        color: $text-3;
      }
    }
  }

  &.action {
    .content {
      .detail {
        & svg {
          path {
            fill: $orange-dark;
          }

          &.icon-stroke path {
            stroke: $orange-dark;
          }
        }
      }
    }
  }

  &:not(.featured) {
    .date-badge {
      width: 40px;
      flex: 0 0 auto;
    }

    .content {
      flex: 1 1 auto;
      width: calc(100% - 72px);

      .content-text {
        flex: 1 1 auto;
        margin-right: 0;
        align-items: stretch;
        width: calc(100% - 199px);

        .title {
          max-width: none;
        }

        .detail {
          .caption {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &.featured {
    padding: 25px;

    &.slider {
      width: 100%;
    }

    .content {
      flex: 1 1 50%;
    }

    .content-text {
      .title {
        max-width: 360px;
      }
    }

    .info {
      margin-left: auto;
      margin-top: 24px;
      flex: 1 1 30%;

      .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
        min-width: fit-content;

        .button {
          margin-bottom: 10px;

          &:first-child {
            margin-right: 10px;
          }
        }
      }

      .info-text {
        max-height: 180px;
        overflow: hidden;
        white-space: pre-wrap;
      }
    }
  }
}
