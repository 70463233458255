@import '../../stylesheets/_variables.scss';

.user-picker-field .react-select {
  .user-container {
    display: flex;
    flex-direction: row;
  }

  .user-container .user-avatar {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 15px;
    margin: 0 calc($grid-gap-basis / 2) 0 0;
  }

  .user-container .user-display-name {
    font-weight: normal;
    margin: 0;
    line-height: calc(35 / 15);
    font-size: 15px;
  }
}
