@import "../../stylesheets/variables";

.event-timelines {
  .event-timeline-row {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 220px 70px 1fr 24px;
    -ms-grid-columns: 1fr $grid-padding 220px $grid-padding 70px $grid-padding 1fr $grid-padding 24px;
    grid-gap: $grid-padding;
    margin-bottom: 16px;

    &.first{
      .vertical-checkbox {
        .input-wrapper {
          margin-top: 25px
        }
      }
    }

    .vertical-checkbox {
      .input-wrapper {
        margin: 15px 0 0;
      }
    }

    & > * {
      &.one {
        -ms-grid-column: 1;
      }

      &.two {
        -ms-grid-column: 3;
      }

      &.three {
        -ms-grid-column: 5;
      }

      &.four {
        -ms-grid-column: 7;
      }

      &.delete {
        -ms-grid-column: 9;
      }
    }

    .delete {
      height: 27px;
      margin-top: 12px;
      align-self: flex-start;
    }
  }
}
