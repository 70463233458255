@import '../NavBarVariables.scss';

.nav-button {
  padding: $nav-bar-button-padding;
  display: block;
  align-items: center;
  min-height: 46.45px;

  font-size: 14px;
  line-height: 16px;
  color: $text-3;
  text-decoration: none;

  span {
    vertical-align: middle;
    opacity: 1;

    #navbar-container:not(.no-anim) & {
      transition: opacity 0.5s ease-in;
    }
  }

  svg {
    vertical-align: middle;
    margin-right: 14px;
    height: 17px;
    width: 17px;

    path {
      fill: $text-3;
    }
  }

  &.collapsed {
    height: 46.45px;
    justify-content: center;

    span {
      opacity: 0;
    }
  }

  &:hover,
  &:focus {
    background-color: $background-grey;
    color: $text-5;
  }

  &.active {
    background-color: $orange-extra-light;
    font-weight: 700;
  }

  &.icon-stroke {
    a {
      svg {
        path {
          stroke: $text-3;
          fill: none;
        }

        path.fill-path {
          stroke: none;
          fill: $text-3;
        }
      }
    }
  }
}
