.upload-avatar-welcome-modal {
  .swal2-modal {
    padding: 56px 24px 32px;
    align-items: center;

    .header {
      margin-bottom: 3px;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .upload-avatar-component {
        margin-top: 32px;
        margin-bottom: 8px;
      }
    }
  }

  .button.cancel {
    min-width: 84px;
    padding: 10px 20px;
  }
}