@import '../../stylesheets/_variables.scss';

.event-call-button.on-card {
  background: $teal-light;
  height: 29.5px;
  min-width: 30px;
  border-radius: 15px;
  position: relative;
  text-indent: -10000px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.call-button:after {
    background: center no-repeat url('../../images/icons/checked_teal.svg');
  }

  &:hover {
    background: $teal;

    &.call-button::after {
      background: center no-repeat url('../../images/icons/checked.svg');
    }
  }
}
