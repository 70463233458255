@import '../../../stylesheets/_variables.scss';

.event-call-button.event-button {
  &:after {
    background: center no-repeat url('../../../images/icons/plus.svg');
  }

  &:hover::after {
    background: center no-repeat url('../../../images/icons/plus_white.svg');
  }
}
