@import '../../stylesheets/variables';

.empty-root {
  width: 100%;
  height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: $shadow;

  .caption {
    text-align: center;
    font-size: 15px;
    line-height: 25px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
