@import '../../stylesheets/variables';

.reports-pie-chart {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;

  .chart-container {
    max-width: 210px;
    max-height: 210px;
    margin-bottom: 30px;
  }

  .legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    & > div {
      margin: 0 12px 24px;

      .color {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin: 0 auto 4px;
      }

      .label {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $text-5;
      }
    }
  }
}
