@import '../../stylesheets/variables';

.events-root {
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  padding-left: $main-horizontal-padding;
  padding-right: $main-horizontal-padding;
  width: calc(100% - 328px);
  max-width: 1112px;
  justify-self: flex-end;

  & > p {
    margin-bottom: 34px;
  }

  .view-switch {
    display: flex;
    align-items: center;

    .switch {
      display: flex;
      align-items: center;
      cursor: pointer;

      & svg {
        margin-right: 5px;
      }

      &:first-child {
        margin-right: 6px;
      }

      .label {
        color: $text-5;
        font-weight: 700;
      }

      &.teal {
        & svg {
          path {
            fill: $blue;
          }
        }

        .label {
          color: $blue;
        }
      }
    }
  }

  .events-tiles {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 50px;

    .event-call-div {
      margin-left: 12px;
      margin-right: 12px;
      margin-bottom: 24px;
      width: 100%;
    }
  }

  .events-table {
    padding-bottom: 30px;

    .GojiCustomTable {
      tr {
        td,
        th {
          &:nth-child(1) {
            width: 600px;
          }
          &:nth-child(2) {
            width: 400px;
          }
          &:nth-child(3),
          &:nth-child(5) {
            width: 200px;
          }
        }
      }
    }
  }

  .event-call-div.featured {
    margin-bottom: 40px;
  }

  .load-button {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .status-cell,
  .table-cell.event-register-action {
    .button-label {
      display: none;
    }

    .register-event-call-btn {
      display: block;
    }

    &.subscribed {
      .button-label {
        display: block;
      }

      .register-event-call-btn {
        display: none;
      }
    }
  }
}

.homepage-sidebar {
  width: 328px;
  min-width: 328px;
  padding: 48px 10px 78px 63px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .switch-label {
    font-size: 13px;
    line-height: 1.38;
    color: $text-3;
  }

  .filter-title {
    color: $text-5;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .filter-header {
    font-weight: 800;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: $text-5;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .filter-section {
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .see-more {
      color: $blue;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.23;
      text-decoration: none;
      cursor: pointer;
      font-family: 'Lato', sans-serif;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
