@import '../../stylesheets/variables';

.leaders {
  .person {
    display: flex;
    align-items: flex-start;
    gap: $grid-padding;

    & > * {
      &.one {
        min-width: 200px;
      }

      &.two {
        flex: 1;
      }

      &.three {
        min-width: 300px;
      }

      &.readonly {
        > label {
          opacity: 1;
        }
      }
    }
  }
}
