@import '../../stylesheets/_variables.scss';

section.eventcall-preferences {
  width: ($grid-gap-basis * 30);
  padding: 0;

  > .input-component-wrapper {
    label::after {
      margin-bottom: calc($grid-gap-basis / 2);
    }
  }

  .event-deadline-fields {
    display: flex;
    flex-direction: column;

    .time-fields {
      display: flex;

      .input-component-wrapper {
        margin: 0 $grid-gap-basis 0 0;
        flex: 1 1;
      }

      .input-component-wrapper:last-child {
        margin-right: 0;
      }

      .timezone-component-field {
        flex: 0 0 120px;
      }
    }
  }

  .preference-details {
    background-color: $background-grey;
    width: 100%;
    padding: $grid-gap-basis;
    margin: -$grid-gap-basis 0 ($grid-gap-basis * 2);

    .input-component-wrapper {
      margin-bottom: $grid-gap-basis;
    }

    > .input-component-wrapper:last-child {
      margin-bottom: 0;
    }
  }
}
