@import '../../stylesheets/variables';

.single-host-container {
  display: flex;
  align-items: center;
  margin: $grid-gap-basis 0;

  .host-name {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: $text-5;
  }

  .host-logo {
    height: 60px;
    width: 60px;
    margin-right: $grid-gap-basis;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .host-subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: $text-3;
  }
}

ul.hosts-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: (30px * 3.5) + ($grid-gap-basis * 3);
  overflow-y: scroll;

  .host-item {
    display: flex;
    align-items: center;
    list-style: none;
    margin: $grid-gap-basis 0 0 $grid-gap-basis;

    &:first-child {
      margin-top: 0;
    }

    .checkbox-component-wrapper {
      margin-bottom: 0;
    }

    .checkbox-component-wrapper label:not(.input-wrapper) {
      display: flex;
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      height: 30px;
    }
  }

  .host-logo {
    height: 30px;
    width: 30px;
  }

  .host-name {
    margin-left: calc($grid-gap-basis / 2);
    font-size: 13px;
    line-height: calc(30 / 13);
    font-weight: 700;
  }
}

.select-communities-description {
  margin-bottom: $grid-gap-basis;
}
