@import '../../stylesheets/variables';

.cohosts {
  .cohost-row {
    display: grid;
    display: -ms-grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 120px 120px 24px;
    -ms-grid-columns: 1fr $grid-padding 1fr $grid-padding 1fr $grid-padding
      120px $grid-padding 120px $grid-padding 24px;
    grid-gap: $grid-padding;
    height: 58px;
    margin-bottom: 16px;
  }
}
