@import '../../stylesheets/variables';

.resources {
  margin-bottom: 24px;

  .resource-row {
    display: flex;
    align-items: flex-start;
    gap: $grid-padding;

    & > * {
      &.one {
        min-width: 200px;
      }

      &.two {
        min-width: 200px;
      }

      &.three {
        flex: 1;
        max-width: 300px;
      }
    }
  }
}
