.organization-auth-tokens-root {
  .GojiCustomTable-wrapper {
    tr {
      td,
      th {
        &:nth-child(3),
        &:nth-child(4) {
          width: 10%;
        }
      }
    }
  }
  .reload-page-hack {
    display: none;
  }
}
