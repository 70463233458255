@import '../../stylesheets/variables';

.speakers {
  .speaker-row {
    display: grid;
    display: -ms-grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 120px 120px 24px;
    -ms-grid-columns: 1fr $grid-padding 1fr $grid-padding 1fr $grid-padding
      120px $grid-padding 120px $grid-padding 24px;
    grid-gap: $grid-padding;
    height: 58px;
    margin-bottom: 16px;

    & > * {
      &.one {
        -ms-grid-column: 1;
      }

      &.two {
        -ms-grid-column: 3;
      }

      &.three {
        -ms-grid-column: 5;
      }

      &.four {
        -ms-grid-column: 7;
      }

      &.five {
        -ms-grid-column: 9;
      }

      &.delete {
        -ms-grid-column: 11;
      }
    }
  }
}
