@import "../../stylesheets/variables";

.community-card-div {
  position: relative;
}

.community-card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: $shadow;
  min-width: 276px;
  height: 100%;
  position: relative;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  padding-bottom: 18px;
  max-width: 360px;

  &:hover,
  &:focus {
    box-shadow: $shadow-hover;

    .info-text {
      color: $text-3;
    }
  }

  .community-image {
    width: 100%;
    height: 168px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .community-logo {
    position: absolute;
    top: 14px;
    left: 12px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  .community-card-info {
    min-height: 60px;
    padding: 9px 20px 0 14px;
    position: relative;

    .info-text {
      word-break: break-word;

      .spacing {
        display: inline-block;
        width: 6rem;
        height: 3rem;
        vertical-align: top;
      }
    }
  }

  .subscribed-indicator {
    display: none;
    position: absolute;
    bottom: 18px;
    right: 25px;
  }

  &.subscribed {
    .subscribed-indicator {
      display: block;
    }
  }
}
