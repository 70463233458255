@import '../../../stylesheets/variables.scss';

.input-component-wrapper .input-wrapper.with-icon {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: calc(10px + 2px);
    left: $grid-gap-basis;
    height: $icon-size;
    width: $icon-size;
  }

  input:not([type='checkbox']):not([type='radio']):not([type='file']) {
    padding-left: (($grid-gap-basis * 2) + $icon-size);
  }
}
