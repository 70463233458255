@import '../../../stylesheets/_variables.scss';

.input-component-wrapper {
  .field-description {
    margin-top: calc($grid-gap-basis / 2);
  }

  label.hide {
    display: none;
  }

  label.hide-keep-space {
    visibility: hidden;
  }
}
