@import '../../../stylesheets/variables';

.attachments {
  margin-bottom: 24px;

  .block-label {
    display: inline-block;
    color: $text-3;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 12px;
  }

  .attachment-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .files-component-wrapper {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
