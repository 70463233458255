@import '../../stylesheets/variables';

.upload-avatar-component {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .upload-avatar {
    border-radius: 50%;
    background-color: $teal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    .preview {
      display: block;
      height: 100%;
      width: 100%;
    }

    .description {
      color: $text-3;
      text-decoration: none;
      margin-top: 11px;
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      padding: 0 20px;
    }

    .hover-description {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($text-3, 0.75);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: none;

      svg {
        path {
          stroke: #fff;
        }
      }

      .description {
        color: #fff;
      }
    }

    &:hover {
      .hover-description {
        display: flex;
      }
    }

    &.with-description {
      background-color: $teal-light;

      &:hover {
        background-color: rgba($teal, 0.4);
      }
    }
  }

  input[type='file'] {
    height: 1px;
    width: 1px;
    position: absolute;
    top: -2px;
  }

  .hidden-file-field {
    height: 1px;
    width: 1px;
    position: absolute;
    top: -5px;
    left: -5px;
    border: none;
  }
}

.avatar-update-info {
  .swal2-header,
  .swal2-html-container {
    padding: 0 28px;
  }
}
