@import '../NavBarVariables.scss';

.nav {
  section {
    padding: 0;
    margin-bottom: $grid-gap-basis * 4;

    &.collapsed {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  ul {
    li {
      list-style-type: none;
      &.icon-stroke {
        a {
          svg {
            path {
              stroke: $text-3;
              fill: none;
            }

            path.fill-path {
              stroke: none;
              fill: $text-3;
            }
          }
        }
      }
    }
  }

  .caption {
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 16px;
    min-height: 16px;
    display: block;
    font-size: 9px;
    letter-spacing: 0.5px;
    color: $text-5;
    margin-bottom: 8px;

    span {
      position: absolute;
      display: block;
      width: 100%;
    }

    .when-not-collapsed {
      text-align: left;
      padding-left: $nav-bar-horizontal-left-padding;
    }

    .when-collapsed {
      text-align: center;
      width: $collapsed-sidebar-width;
      padding-left: 2px; /* fixes weird centering alignment bug */
    }
  }
}

#navbar-container {
  &:not(.no-anim) .when-collapsed,
  &:not(.no-anim) .when-not-collapsed {
    transition: opacity 0.5s ease-in;
  }

  .when-collapsed {
    &.anim-opacity {
      opacity: 0.0;
    }
  }

  .when-not-collapsed {
    &.anim-opacity {
      opacity: 1.0;
    }
  }

  &.collapsed {
    .when-not-collapsed.anim-opacity {
      opacity: 0.0;
    }

    .when-collapsed.anim-opacity {
      opacity: 1.0;
    }
  }
}
