@import '../../stylesheets/variables';

.card {
  background: #ffffff;
  box-shadow: $shadow;
  border-radius: $box-radius;
  display: flex;
  position: relative;
  align-items: start;
  flex-direction: column;
}

.card-header {
  top: 0;
  padding: $grid-gap-basis calc($grid-gap-basis * 2);
  width: 100%;
  min-height: calc($line-height-h1 + $grid-gap-basis);
  display: inline-flex;
  justify-content: space-between;
  border-bottom: $border-color solid 1px;
}

.card-child {
  width: 100%;
  padding: calc($grid-gap-basis * 2);
}
