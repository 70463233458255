.communities-tiles {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 50px;

  .community-card-div {
    height: auto;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 312px;
  }
}

.load-button {
  display: flex;
  justify-content: center;
  margin: 45px 0 70px;
}
