@import '../../stylesheets/variables';
@import '../../stylesheets/mixins';

.info-panel {
  padding: 16px 24px 14px;
  min-width: 220px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: $shadow;
  position: relative;

  &:hover,
  &.selected {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $teal-dark;
      border-radius: 0 0 4px 4px;
    }
  }

  h1 {
    color: $teal-dark;
    margin-bottom: 2px;
  }

  .caption {
    font-weight: 700;
  }

  &:first-child {
    margin-right: 24px;
  }
}
