@import '../../stylesheets/variables';

.user-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  .user-avatar {
    display: block;
    position: relative;
    width: 130px;
    height: 130px;
    line-height: 130px;
    margin-bottom: 8px;
    font-size: 45px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: #4aa199;
  }

  .upload-avatar-component {
    margin-bottom: 8px;
  }

  .pill.leader {
    margin-top: -$pill-large-height;
    position: relative;
  }

  .user-display-name {
    margin-top: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: calc(22 / 16);
    word-wrap: break-word;
  }

  .user-title {
    font-size: 15px;
    line-height: calc(22 / 15);
  }
}
