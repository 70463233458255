@import '../../stylesheets/variables';
@import '../../stylesheets/mixins';

.leadership-root {
  height: 100%;
  min-height: calc(100vh - #{$header-height} - #{$footer-height} - 120px);

  .section-header {
    .right {
      display: flex;

      .input-component-wrapper {
        margin-right: 12px;
      }

      a {
        margin-right: 20px;
      }

      &.bottom {
        align-self: flex-end;

        button {
          margin-left: 4px;
        }
      }
    }
  }

  .data {
    padding-bottom: 80px;

    .GojiCustomTable-root {
      width: 100%;

      .GojiCustomTable-wrapper {
        border-radius: 0 4px 4px 0;
        height: 100%;
        background-color: #ffffff;

        tr {
          td,
          th {
            &:nth-child(1) {
              width: 40%;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              width: 20%;
            }
            &:nth-child(5) {
              width: 10%;
            }
          }
        }
      }
    }

    &.subscribers {
      .GojiCustomTable-root .GojiCustomTable-wrapper tr {
        td,
        th {
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: auto;
          }
          &:nth-child(5) {
            width: 40px;
          }
          &:nth-child(6) {
            width: 150px;
          }
        }
      }
    }
  }
}
