@import '../../stylesheets/variables';

.section-title {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: $text-5;
}

.small-text {
  font-size: 13px;
  line-height: 18px;
  color: $text-3;
}
