@import '../../stylesheets/variables';

.cohorts-participants-root {
  height: 100%;
  min-height: calc(100vh - #{$header-height} - #{$footer-height} - 120px);

  .section-header {
    .right {
      display: flex;

      > a {
        margin-right: 20px;
      }

      &.bottom {
        align-self: flex-end;

        .menu-wrapper {
          margin-left: 4px;
        }
      }
    }

    .participants-more-menu .menu-body {
      z-index: 100;
    }
  }

  .data {
    margin-bottom: 80px;
  }

  .GojiCustomTable-wrapper {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 20%;
        }
      }
    }
  }

  .reload-page-hack {
    display: none;
  }

  .menu-wrapper .menu-toggler {
    width: 44px;
    height: 44px;
  }
}
