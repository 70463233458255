.organization-digest-fields {
  .digest-time-row {
    display: flex;
    align-items: flex-start;
    max-width: 580px;

    .input-component-wrapper {
      padding-right: 6px;
      flex: 1 1;

      &:last-child {
        padding-right: 0;
      }

      &.organization-digest-month-week,
      &.organization-digest-timezone {
        flex: 0 0 120px;
      }
    }
  }

  .organization-digest-month-week + .organization-digest-week-day {
    label {
      visibility: hidden;
    }
  }

  .organization-digest-timezone label {
    visibility: hidden;
  }
}
