@import "../../stylesheets/variables";

.meeting-minutes {
  max-width: 900px;
  margin-bottom: $main-bottom-padding;

  .meeting-minutes-item-row {
    display: grid;
    display: -ms-grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 2fr 24px;
    -ms-grid-columns: 1fr $grid-padding 1fr $grid-padding 2fr 24px;
    grid-gap: $grid-padding;

    & > * {
      &.one {
        -ms-grid-column: 1;
      }

      &.two {
        -ms-grid-column: 3;
      }

      &.three {
        -ms-grid-column: 5;
      }

      &.delete {
        -ms-grid-column: 7;
      }
    }
  }
}
