@import '../../stylesheets/variables';

.agenda {
  max-width: 1400px;

  .agenda-item-row {
    display: grid;
    display: -ms-grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1.5fr 24px;
    -ms-grid-columns: 1fr $grid-padding 1fr $grid-padding 1fr $grid-padding
      1.5fr $grid-padding 24px;
    grid-gap: $grid-padding;
    margin-bottom: 16px;

    & > * {
      &.one {
        -ms-grid-column: 1;
      }

      &.two {
        -ms-grid-column: 3;
      }

      &.three {
        -ms-grid-column: 5;
      }

      &.four {
        -ms-grid-column: 7;
      }

      &.delete {
        -ms-grid-column: 9;
      }
    }
  }
}
