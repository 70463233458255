@import '../../stylesheets/mixins';

.membership-filters {
  box-shadow: $shadow;
  border-radius: 4px 0 0 4px;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 36px;
  display: flex;
  flex-grow: 1;

  .input-component-wrapper {
    flex: 1 1;
    min-width: 200px;
    max-width: 350px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  .search {
    &::after {
      top: 48px;
    }

    .input-wrapper input {
      line-height: 18px;
    }
  }
}
