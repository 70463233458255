@import '../../stylesheets/variables';

.reports-index {
  h3 {
    margin-bottom: 16px;
  }

  .header-left {
    .link {
      margin-right: 7px;
    }
  }

  .togglable-filter {
    display: flex;
    margin: 0 12px 0 0;
  }

  .subtitle {
    max-width: 646px;
    margin: -11px 0 35px;
  }

  .type-block {
    .input-component-wrapper {
      max-width: 278px;
      margin-bottom: 43px;
    }
  }

  .comment {
    margin-top: 16px;
    color: $text-5;
  }
}
