@import '../../stylesheets/_variables.scss';

.event-call-action {
  .join-waitlist-action {
    position: static;
  }

  // white-space: nowrap;

  &.on-card {
    position: absolute;
    bottom: $grid-gap-basis * 2;
    right: $grid-gap-basis * 2;
  }
}
