@import '../../../stylesheets/variables';

.join-waitlist-action {
  position: absolute;
  bottom: $card-padding;
  right: $card-padding;

  button {
    &.waitlist-oncard {
      &::before {
        background-image: url('../../../images/icons/plus.svg');
      }

      &:hover,
      &:focus {
        &::before {
          background-image: url('../../../images/icons/plus_white.svg');
        }
      }
    }
  }
}
