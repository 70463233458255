#yearbook-root {
  margin-bottom: 36px;

  .members-tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .user-container {
      flex: 0 0 calc(20% - 19.2px);
      margin-bottom: 0;
    }
  }

  .link-container {
    display: flex;
    justify-content: space-between;
  }
}
