@import '../NavBarVariables.scss';

.basic-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: $sidebar-width;

  .upload-avatar-component {
    padding-right: $grid-gap-basis;
  }

  .profile {
    overflow: hidden;

    .profile-inner {
      width: calc(
        $sidebar-width - $nav-bar-horizontal-left-padding - $avatar-width -
          $grid-gap-basis - $nav-bar-horizontal-right-padding -
          $collapse-button-width
      );
    }

    a {
      text-decoration: none;
    }

    * {
      overflow-wrap: break-word;
    }

    h3 {
      font-size: 18px;
      line-height: 25px;
    }

    .pronoun {
      font-weight: bold;
    }
  }
}
